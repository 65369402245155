import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSnackBarMessageComponent, SnackBarMessage } from '../app-com/snack-bar-message-component/snack-bar-message.component';
import { AppErrorTypeEN, AppMessage } from '../app-shared/model/app-message';

@Injectable({providedIn:'root'})
export class AppMessageService {
  public isProcessing = false;
  public pageTitle: string = 'Trang chủ';
  public isSnackShowing = false;
  constructor(
    private _snackBar: MatSnackBar,
    private zone: NgZone,
  ){}

  startProcessing(){
    if (!this.isProcessing){
      this.isProcessing = true;
      this.hideSnackBar();
    }
  }
  stopProcessing(){
    if (this.isProcessing){
      this.isProcessing = false;
    }
  }
  showError(msgItem: AppMessage) {
    this.stopProcessing();
    if (msgItem.errorType === AppErrorTypeEN.UnAuthorized){
      let sbMessage = {type:'error',messages: ['Bạn không được phép đăng nhập và sử dụng PM này']}
      this.showSnackBar(sbMessage);
    }
    else{
      let sbMessage = {type:'error',messages: [msgItem.message!]}
      this.showSnackBar(sbMessage);
    }
  }
  add(message: string) {
    this.stopProcessing();
    let sbMessage = {type:'error',messages: [message]}
    this.showSnackBar(sbMessage);
  }
  addErrors(errs: string[]) {
    this.stopProcessing();
    let sbMessage = {type:'error',messages: errs}
    this.showSnackBar(sbMessage);
  }
  addInfo(message: string) {
    this.stopProcessing();
    let sbMessage = {type:'information',messages: [message]}
    this.showSnackBar(sbMessage);
  }
  addWarning(message: string) {
    this.stopProcessing();
    let sbMessage = {type:'warning',messages: [message]}
    this.showSnackBar(sbMessage);
  }
  //Snack Bar
  hideSnackBar() {
    //this.isProcessing = false;
    this.zone.run(()=>{
      if (this.isSnackShowing){
        this.isSnackShowing = false;
        this._snackBar.dismiss();
      }
    });
  }
  showSnackBar(sbMessage: SnackBarMessage){
    this.zone.run(() => {
      if (!this.isSnackShowing){
        const sbRef = this._snackBar.openFromComponent(AppSnackBarMessageComponent,{
          data: sbMessage
        })
        sbRef.afterDismissed().subscribe(()=>{
          this.isSnackShowing = false;
        })
        this.isSnackShowing = true;
      }
    });
  }


}

