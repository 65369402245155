export const environment = {
  appVersion: 'Công ty Công nghệ Bao Bì Tiến Phát - version 2.0.0-rc.1',
  production: true,
  apiUrl: 'https://bbtienphat-api.baobif.com/',
  fontUrl: 'https://bbtienphat-v2.baobif.com/',
  companyInfo:{
    companyName: 'Công ty Công nghệ Bao Bì Tiến Phát'
  },
  pdfConfig:{
    dorA4Full: "SdDorPdfA4FullM1"
  },
  firebase: {
    projectId: 'idstestbaobifcom',
    appId: '1:777769681587:web:acee32493317700f3ce6c6',
    databaseURL: 'https://idstestbaobifcom.firebaseio.com',
    storageBucket: 'idstestbaobifcom.appspot.com',
    apiKey: 'AIzaSyBrYKQpU8XxBYDl-NkJ6TCIltW6uAkj16c',
    authDomain: 'idstestbaobifcom.firebaseapp.com',
    messagingSenderId: '777769681587',
    measurementId: 'G-4YRB2WY7KP',
  },
};
